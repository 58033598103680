body,
html,
div,
blockquote,
img,
label,
p,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
ul,
ol,
li,
dl,
dt,
dd,
form,
a,
fieldset,
input,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
}

body {
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  padding: 0.6em 0;
  margin: 0 15px;
}

ul,
ol {
  list-style: none;
}

a {
  color: var(--main-colour);
  text-decoration: none;
}

/* Simple reset above */

:root {
  --main-colour: #ff7373;
  --secondary-colour: #c4c4c4;
  --teal: #11a6ad;
  --fourth-colour: #eebb4d;
  --black: #1e1e1e;
}

html {
  scroll-padding-top: 80px;
}

body {
  font-family: "Work Sans", sans-serif;
  color: rgb(36, 31, 31);
  background-color: black;

  text-align: center;
}

.App {
  background-color: var(--black);
}

#pink-svg {
  position: absolute;
  width: 900px;
  height: 900px;
  z-index: 0;
  left: -307px;
  top: 100px;
  animation-name: pinkBlob;
  opacity: 0;

  animation-duration: 22s;
  animation-fill-mode: forwards;
}

#teal-svg {
  position: absolute;
  width: 1378px;
  height: 928px;
  z-index: 0;
  right: -20vw;
  top: -10vh;
  animation-name: tealBlob;
  opacity: 0;

  animation-duration: 22s;
  animation-fill-mode: forwards;
}

#teal-outline {
  position: absolute;
  width: 1378px;
  height: 928px;
  z-index: 0;
}

#pink-outline {
  position: absolute;
  width: 1378px;
  height: 928px;
  left: -300px;
  top: 2700px;
  z-index: 0;
}
#teal-outline2 {
  position: absolute;
  width: 1378px;
  height: 928px;
  right: -300px;
  top: 4700px;
  z-index: 0;
}

#pink-outline2 {
  position: absolute;
  width: 1378px;
  height: 928px;
  left: -300px;
  top: 6700px;
  z-index: 0;
}
#teal-outline3 {
  position: absolute;
  width: 1378px;
  height: 928px;
  right: -300px;
  top: 8700px;
  z-index: 0;
}

@media screen and (max-width: 1600px) {
  #teal-svg {
    width: 1100px;
    right: -30vw;
  }
}

@media screen and (max-width: 1200px) {
  #teal-svg {
    width: 1100px;
    right: -60vw;
  }
}

@media screen and (max-width: 900px) {
  #teal-svg {
    width: 1400px;
    right: -1160px;
  }

  #pink-svg {
    top: -70px;
    left: -500px;
  }

  #teal-outline {
    left: -300px;
  }
}

/* --------------------- Navbar */
nav {
  background-color: black;
  color: var(--main-colour);
  padding: 10px 0px;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  position: fixed;
  width: 100%;
  z-index: 3;
  display: grid;
}

nav a {
  margin-left: 10px;
  color: var(--main-colour);
}

/* --------------------------------- NEW DASHBOARD  */

.title-dashboard-space {
  text-align: left;
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(var(--secondary-colour), white);
}

.glad-youre-here {
  padding: 150px 0px;
}

.title-dashboard-space h1 {
  font-size: 9rem;
  font-weight: 500;
  color: white;
  width: 80%;
  margin: auto;
  padding: 30px 0px;
  position: sticky;
  top: 100px;
  animation-name: slideIn;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.title-dashboard-space h1:nth-of-type(2) {
  animation-delay: 0.2s;
}

.dark-text-div {
  width: 100%;
  color: var(--main-colour);
  background-color: var(--black);
  position: relative;
  padding: 150px 0px 400px 0px;
}

.dark-text-div p a {
  font-weight: 400;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  animation-name: slideIn;
  opacity: 0;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.dark-text-div p a:hover {
  color: var(--teal);
}

.dark-text-div p {
  margin: auto;
  width: 80%;
  font-size: 2.3rem;
  font-weight: 400;
  line-height: 45px;
  animation-name: slideIn;
  opacity: 0;
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1141px) {
  .title-dashboard-space h1 {
    font-size: 7rem;
    padding: 5px 0px;
    line-height: 90px;
  }
  .dark-text-div p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 905px) {
  .title-dashboard-space h1 {
    font-size: 5rem;
  }
  .dark-text-div p {
    font-size: 1.8rem;
  }
}

/* ------------------------------------ Projects */

.projects {
  padding-top: 300px;
  background-image: linear-gradient(var(--black), var(--secondary-colour));
  transition: 0.5s;
  position: relative;
  overflow: hidden;
}

.variable-title {
  position: sticky;
  z-index: 2;
  top: 250px;
  font-size: 7rem;
  color: white;

  font-weight: 700;
  height: 200px;
  width: 80%;
  text-align: left;
  margin: 0 auto;
  animation-name: fadeIn;
  opacity: 0;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.variable-title:hover {
  color: white;
}

.variable-title a {
  transition: 0.5s;
}

.ref-div {
  transform: translateY(-200px);
}

.project {
  width: 700px;
  margin: 500px auto;
  transition: 2s;
}

.project img {
  width: 100%;
  border-radius: 75px;

  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1546px) {
  .variable-title {
    position: sticky;
    top: 320px;
  }
  .ref-div {
    transform: translateY(-300px);
  }
  .intro {
    padding-top: 225px;
  }
  .project {
    width: 1000px;
    margin: 700px auto;
  }
  .variable-title {
    font-size: 9rem;
  }
}

@media screen and (max-width: 1146px) {
  .variable-title {
    font-size: 6rem;
  }
  .ref-div {
    transform: translateY(120px);
  }
}

@media screen and (max-width: 966px) {
  .variable-title {
    font-size: 5rem;
  }
}

@media screen and (max-width: 850px) {
  .project {
    width: 75%;
    margin: 300px auto;
  }
  .variable-title {
    font-size: 4rem;
  }
}

@media screen and (max-width: 539px) {
  .intro {
    padding-top: 90px;
  }
  .variable-title {
    font-size: 4rem;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .project {
    width: 85%;
    margin: 500px auto;
  }
}

@media only screen and (max-device-width: 380px) and (orientation: landscape) {
  .variable-title {
    top: 150px;
  }
}

/* ---------------------------------------- Individual Project Page */
/* unfortunately necessary because of the nav not extending in landscape mode */
.project-page-background {
  background-image: linear-gradient(
    var(--secondary-colour),
    white,
    var(--secondary-colour)
  );

  position: relative;
  overflow: hidden;
}

.project-page {
  display: grid;
  padding: 0px 0px 100px 0px;
  grid-template-rows: 1fr auto auto;
  width: 80%;
  margin: auto;
  text-align: left;
}

.back-bar {
  padding-top: 50px;
  text-align: left;
  margin: 0px 30px;
  font-size: 3rem;
  position: sticky;
  top: 0;
}

#next-project-link {
  float: right;
  margin-right: 10px;
}

.back-bar a {
  color: white;
  margin: 0;
  padding: 0;
  z-index: 2;
}

.project-page h1 {
  z-index: 0;
  width: auto;
  margin: 0px auto;
  padding: 10px 0px;
  font-size: 6rem;
  font-weight: 500;
  border-bottom: 3px solid white;
  animation-name: fadeIn;
  color: var(--black);
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.project-page h2 {
  font-size: 3rem;
  font-weight: 400;
  color: white;
  transition: 0.3s;
}

.project-page a h2:nth-of-type(1) {
  animation-name: slideIn;
  animation-delay: 0.2s;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.project-page a h2:nth-of-type(2) {
  animation-name: slideIn;
  animation-delay: 0.4s;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.project-page h3 {
  font-size: 1.2rem;
  margin: 0px;
  font-weight: 500;
}

.project-page p {
  font-size: 1.1rem;
  font-weight: 300;
  padding-top: 20px;
  border-top: 1px solid white;
  line-height: 30px;
}

.project-page a {
  cursor: pointer;
}

.project-page h2:hover {
  color: var(--main-colour);
}

.project-page img {
  width: 80%;
  border-radius: 25px;
  animation-name: fadeIn;
  animation-delay: 0.3s;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.pp-image-and-links {
  display: grid;
  grid-template-columns: 3fr 2fr;
  margin: 50px 0px;
  place-items: center start;
}

.pp-links {
  border-left: 1px solid;
}

.pp-info-columns {
  margin: 0px auto;
  padding: 50px 0px;
  border-top: 1px solid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.pp-info-columns div {
  margin: 0px 40px;
}

.pp-info-columns div:nth-of-type(1) {
  animation-name: fadeIn;
  animation-delay: 0.4s;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.pp-info-columns div:nth-of-type(2) {
  animation-name: fadeIn;
  animation-delay: 0.5s;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.pp-info-columns div:nth-of-type(3) {
  animation-name: fadeIn;
  animation-delay: 0.6s;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
  .project-page h1 {
    font-size: 4rem;
  }

  .project-page h2 {
    font-size: 2rem;
  }

  .project-page h3 {
    font-size: 1rem;
  }
  .project-page p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 850px) {
  .project-page {
    width: 90%;
  }

  .pp-info-columns div {
    margin: 0px 20px;
  }
}
@media screen and (max-width: 720px) {
  .project-page h3 {
    font-size: 0.9rem;
  }
  .project-page p {
    font-size: 0.8rem;
  }
  .pp-info-columns div {
    margin: 0px 20px;
  }
  .back-bar {
    padding-top: 50px;
    text-align: left;
    margin: 0px 10px;
    font-size: 2rem;
    position: sticky;
    top: 0;
  }
}

@media screen and (max-width: 539px) {
  .project-page h1 {
    font-size: 2.5rem;
  }

  .project-page img {
    width: 90%;
  }
  .pp-info-columns {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    padding: 30px 0px 100px 0px;
  }
  .pp-info-columns div {
    margin: 10px 0px;
  }
}

@media screen and (max-width: 539px) {
  .project-page h2 {
    font-size: 1.4rem;
  }
}
/* ------------------------------------------------ Footer */
.footer {
  background-color: var(--black);
  color: white;
  width: 100%;
}
.footer-content {
  padding: 100px 0px;

  display: grid;
  grid-template-columns: 1fr 2fr;
  place-items: center;
  width: 80%;
  margin: auto;
}

.ft-list {
  padding: 30px 30px;
  border-right: 1px solid;
}

.ft-content-right p {
  width: 90%;
  margin: auto;
  text-align: left;
  font-weight: 300;
  line-height: 22px;
  animation-name: fadeIn;
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.footer-content h3 {
  font-size: 2.5rem;
  font-weight: 400;
  cursor: pointer;
}

.footer-content h4 {
  font-size: 2.5rem;
  font-weight: 300;
  cursor: pointer;
  animation-name: fadeIn;
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.skills {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0 20px;
  text-align: left;
  animation-name: fadeIn;
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.skills h5 {
  margin: 0;
  padding: 10px 0;
  font-weight: 600;
  font-size: 1.3rem;
}

.skills li {
  margin: 5px 0;
}

.copyrights {
  font-weight: 200;
  font-size: 0.5rem;
  padding: 0px 0px 20px 0px;
}

@media screen and (max-width: 1000px) {
  .footer-content {
    width: 100%;
    height: 400px;
  }
}

@media screen and (max-width: 800px) {
  .skills {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-left: 30px;
    gap: 20px 20px;
  }
}

@media screen and (max-width: 650px) {
  .footer-content {
    height: 300px;
    place-items: start;
  }
  .footer-content h3 {
    font-size: 2rem;
  }
  .footer-content h4 {
    margin-top: 70px;
  }
  .footer-content p {
    font-size: 0.7rem;
  }
  .skills h5 {
    font-size: 1rem;
  }

  .skills li {
    margin: 5px 0;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 500px) {
  .ft-list {
    padding: 30px 10px;
  }
  .footer-content h4 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .footer-content h3 {
    font-size: 1.1rem;
  }
  .footer-content p {
    font-size: 0.6rem;
  }
  .skills h5 {
    font-size: 0.9rem;
  }

  .skills li {
    margin: 5px 0;
    font-size: 0.7rem;
  }
}

/* PATCH PLANTS ONLY */

.patch {
  padding: 50px 0px;
  display: grid;
  place-items: center;
  background-position: center;
  background-size: cover;
}

.patch h1 {
  font-size: 4rem;
  font-weight: 500;
  animation-name: fadeIn;
  opacity: 0;

  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.patch h2 {
  font-size: 2rem;
  font-weight: 400;
  animation-name: fadeIn;
  opacity: 0;
  animation-delay: 0.4s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.patch img {
  width: 500px;
  max-width: 90%;
  border-radius: 25px;
  border: 5px solid white;
  animation-name: fadeIn;
  opacity: 0;
  animation-delay: 0.2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.patch a {
  animation-name: slideIn;
  opacity: 0;
  animation-delay: 0.8s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 400px) {
  .patch h1 {
    font-size: 3rem;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(30px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0px, 30px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes pinkBlob {
  from {
    opacity: 0.8;
    transform: translate(0px, 300px);
    transform: rotate(30deg);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
    transform: rotate(0deg);
  }
}

@keyframes tealBlob {
  from {
    opacity: 0.8;
    transform: rotate(30deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
